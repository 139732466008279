<template>
  <BaseLayout title="邀请好友来使用" subtitle="最高享 30% 佣金" :bg="background" fg="#FFF" height="60px">
    
    <van-notice-bar class="record" left-icon="volume-o" :scrollable="false">
      <van-swipe class="record-item" vertical :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(item,index) in state.list_new" :key="index">{{item.name}} 邀请 {{item.num}} 位好友赚取 ${{item.amount}}</van-swipe-item>
      </van-swipe>
    </van-notice-bar>

    <div class="info">

      <div class="block">
        <div class="title">活动说明</div>
        <div class="content">
          每邀请 1 位好友注册成功可获得 10 个积分，积分可在用户中心的卡券里面兑换 VIP 会员或其他增值功能；如果被邀请的好友购买了 VIP 会员，则可以获得购买金额的 30% 作为佣金奖励，佣金实时进入钱包，可消费、转账或申请提现。
        </div>
      </div>

      <div class="block">
        <div class="title">奖励排行</div>
        <div class="content">
          <van-cell v-for="(item,index) in state.list_top" :key="index" :title="item.name" :value="'$' + item.amount">
            <template #icon><van-tag size="large" :color="'#F' + Math.floor(index*2.4).toString() + index.toString()" round>{{index+1}}</van-tag>&nbsp;&nbsp;</template>
          </van-cell>
        </div>
      </div>

      <div class="block">
        <div class="title">我的邀请</div>
        <van-row class="content mine" gutter="2" justify="space-between" align="center">
          <van-col span="6" align="right">累计邀请：</van-col>
          <van-col span="12">0 个好友，获得 $0 佣金</van-col>
          <van-col span="6" class="right" @click="$router.push('/my/invite')">查看&nbsp;&gt;&gt;</van-col>
          <van-col span="6" align="right">邀请码：</van-col>
          <van-col span="18" @click="$copy(inviteCode)">{{inviteCode}}</van-col>
          <van-col span="6" align="right">邀请链接：</van-col>
          <van-col span="18" class="van-ellipsis" @click="$copy(inviteURL)">{{inviteURL}}</van-col>
          <van-col span="4"><van-button plain icon="qr" icon-position="right" color="#F60" @click="state.show=true"></van-button></van-col>
          <van-col span="20"><van-button block icon="share" icon-position="left" color="#F60" @click="$toast('海报制作中')">海报邀请</van-button></van-col>
        </van-row>
      </div>
      
    </div>

    <van-popup v-model:show="state.show" round closeable position="top">
      <div class="qrcode"><div class="face">面对面邀请</div>
        <van-image :src="'https://api.qrserver.com/v1/create-qr-code/?size=300x300&margin=10&ecc=H&data=' + inviteURL" width="200" height="200" radius="10"></van-image>
        
      </div>
    </van-popup>
    
    <br/>

  </BaseLayout>
</template>

<style scoped>
.record {
  margin: 20px;
  background: #FFF;
  border-radius: 5px;
  border:solid 2px #EEE;
  font-size: 14px;
  opacity: 0.7;
}
.record-item {
  height: 40px;
  line-height: 40px;
}


.info {
  margin: 10px;
  background: #EEE;
  border-radius: 20px;
  display: block;
}


.block {
  margin-bottom: 10px;
  padding: 10px;
  background: #FFF;
  border-radius: 10px;
  color: #333;
  font-size: 14px;
  line-height: 24px;
  display: block;
}

.title {
  margin-bottom: 5px;
  padding: 10px;
  background: linear-gradient(90deg,#EEC, #FFF);
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  font-weight: 600;
}
.content {
  height: auto;
  padding: 5px;
  line-height: 24px;
}
.mine {
  height: 160px;
}
.right {
  text-align: right;
}

.qrcode {
  height: 300px;
  padding-top: 10px;
  background: linear-gradient(180deg, #F60, #F9F9F9 60%, #F9F9F9);
  text-align:center;
}
.face {
  margin: 20px;
  color: #FFF;
  font-size: 20px;
}

</style>

<script>
import { reactive } from 'vue'
import {mapState, mapGetters} from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

  setup(){
    const state = reactive({
      list_new: [],
      list_top: [],
      show: false,
    });
    return {state};
  },

  created() {
    for (let m in "0123456789".split("")){
      this.state.list_new.push({
        name: "币友" + this.random(4),
        num: parseFloat(this.random(2)),
        amount: parseFloat(this.random(3)),
      });
    }

    var arr = [88, 77, 75, 64, 60];
    for (let n in arr){
      this.state.list_top.push({
        name: "币友" + (arr[n] * 73).toString().substr(0, 4),
        amount: parseFloat(arr[n] * 88),
      });
    }
  },

  computed: {
    background() {
      return "#ffd902 url('" + require('../assets/bg_invite.jpg') + "') 10% top no-repeat";
    },

    inviteCode(){
      if (!this.authorized)
        return "请先登录";
      return btoa(this.profile.id).replace("==", "");
    },
    inviteURL(){
      if (!this.authorized)
        return "请先登录";
      return "https://m.huoshen.app/#/invite/" + this.inviteCode;
    },

    ...mapState(["profile"]),
    ...mapGetters(["authorized", "uid", "nickname", "balance"]),
  },

  methods: {
    random(len){
      return (Math.random() * 999999999).toString().replace(".", "").substr(1, len);
    },
  }
}
</script>
